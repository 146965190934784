body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #252525;
  margin: 0;
padding: 0;
}
h2 {
  font-family: "poppins", sans-serif;
  font-size: 28px;
  line-height: 47px;
  font-weight: 400;
}
h1 {
  font-family: "bayon", sans-serif;
  font-size: 65px;
  line-height: 80px;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
